<!-- 经典 -->
<template>
  <div :class="['container', nobody ? 'container-minus' : '']">
    <div class="header">
      <div :class="['logo', campaign.post.has_green_card ? 'logo-gc' : '']"></div>
      <div class="box" :style="{ height: img.height }">
        <img class="bgimg" :src="img.src" />
        <div :class="['main', mainBgImg]">
          <div v-if="!nobody" class="info">
            <div class="avatar-box">
              <img
                v-for="avatar of campaign.avatars"
                :key="avatar"
                class="avatar"
                :src="avatar"
              />
            </div>
            <span class="desc">{{ campaign.text }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="left">
        <div class="title">
          <img v-if="campaign.post.has_green_card" src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/0187370e-bf7d-c5a7-db9e-d2662c46b799?x-oss-process=style/origin">
          <span>{{ campaign.campaign.title }}</span>
        </div>
        <div class="item">
          <img class="" src="../../../../assets/images/clock.png" />
          <span>{{ generateCampaignDate }}</span>
        </div>
        <div class="item">
          <img class="" src="../../../../assets/images/poi-2.png" />
          <span>{{ campaign.post.poi.name }}</span>
        </div>
        <div class="item">
          <img class="" :src="campaign.post.author.avatar_url" />
          <span>{{ campaign.post.author.nickname }}</span>
          <img class="" src="../../../../assets/images/club-2.png" />
        </div>
      </div>
      <div class="right">
        <img class="" :src="qrcode" alt="" />
        <span>微信扫一扫</span>
      </div>
    </div>
  </div>
</template>

<script>
import $fetch from "@/utils/fetch.js";
import { formatFeedsDate } from "@/utils/utils.js";
export default {
  name: "details-poster",
  props: {
    campaignId: String,
  },
  data() {
    return {
      campaign: {},
      qrcode: "",
      nobody: true,
      img: {}
    };
  },
  computed: {
    /** 生成活动开始时间 */
    generateCampaignDate() {
      let {
        campaign: { start_time, end_time },
      } = this.campaign;
      start_time = +new Date(start_time);
      end_time = +new Date(end_time);
      return formatFeedsDate(start_time, end_time);
    },
  },
  async created() {
    const { campaignId } = this;
    this.getCampaign(campaignId);
    this.getWechatCode(campaignId);
  },
  methods: {
    /** 获取活动详情 */
    getCampaign(campaignId) {
      Promise.all([$fetch.post("GetSharedCampaign", {
        postId: "",
        campaignId,
      }), $fetch.post("GetActivityParticipantsSummary", {
        campaignId
      })]).then(res => {
        let [campaign, summary] = res;
        this.campaign = {
          ...campaign,
          text: summary.content,
          avatars: summary.avatar_urls,
        };
        const img = campaign.post.images[0];
        console.log(campaign, img);
        if (img.video_info) {
          this.img = {
            src: img.video_info.thumbnail,
            height: img.video_info.height_px
          }
        } else if (img.image_info) {
          this.img = {
            src: img.image_info.thumbnail_url,
            height: img.image_info.height_px
          }
        }
        const nobody = !campaign.text;
        this.nobody = nobody;
        this.mainBgImg =
          campaign.post.has_green_card
            ? nobody ? 'gc_nobody' : 'gc_someone'
            : nobody ? 'main_nobody' : 'main_someone';
      });
    },
    /** 获取活动的小程序码 */
    getWechatCode(campaignId) {
      const _ = this;
      $fetch
        .post("SharePost", {
          campaignId,
        })
        .then((code) => {
          _.qrcode = "data:image/jpeg;base64, " + code.mini_program_code_image;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
$multipleCount: 1.146;

.container {
  @include column;
  position: relative;
  width: 100%;
  background-color: #fff;
  box-sizing: content-box;
  overflow: hidden;

  > .header {
    @include column;
    width: 100%;
    background-color: #47ffa6;
    overflow: hidden;

    $marginAside: px2rem(32 * $multipleCount);

    > .logo {
      width: 100%;
      height: px2rem(80 * $multipleCount);
      background-repeat: no-repeat;
      background-position: left top;
      background-size: contain;
      background-image: url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/0187371d-c3aa-cebb-008e-4bfaaabbe1b8?x-oss-process=style/origin");
    }

    > .logo-gc {
      background-image: url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/0187371d-c3a9-a4af-2b0c-58de66d906f7?x-oss-process=style/origin");
    }

    > .box {
      position: relative;
      width: calc(100% - px2rem(32 * $multipleCount * 2));
      margin: px2rem(8 * $multipleCount) $marginAside
        px2rem(32 * $multipleCount);
      background-color: #fff;
      padding-bottom: px2rem(116 * $multipleCount);
      border-radius: px2rem(8 * $multipleCount);
      overflow: hidden;

      > .bgimg {
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
        object-fit: cover;
      }

      > .main {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: px2rem(168 * $multipleCount);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: contain;

        > .info {
          @include row(flex-start, center);
          position: absolute;
          bottom: px2rem(82 * $multipleCount);
          left: px2rem(16 * $multipleCount);
          width: 75%;
          height: px2rem(40 * $multipleCount);
          transform-origin: center center;
          transform: rotate(-3deg);
          overflow: hidden;

          > .avatar-box {
            @include row(flex-start, center);
            height: px2rem(40 * $multipleCount);

            > .avatar {
              display: block;
              box-sizing: border-box;
              width: px2rem(40 * $multipleCount);
              height: 100%;
              background-color: #ccc;
              border-radius: 50%;
              border: px2rem(2 * $multipleCount) solid #fff;

              &:not(:first-child) {
                margin-left: px2rem(-8 * $multipleCount);
              }
            }
          }

          > .desc {
            color: #1ffd91;
            font-size: px2rem(24 * $multipleCount);
            margin-left: px2rem(8 * $multipleCount);
            font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }

      > .main_nobody {
        height: px2rem(180 * $multipleCount);
        background-image: url(https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01841deb-1b03-3270-f909-dc5995c4168d?x-oss-process=style/origin);
      }

      > .main_someone {
        background-image: url(https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01841dda-94df-f072-6a10-6dae50fc836e?x-oss-process=style/origin);
      }

      > .gc_nobody {
        height: px2rem(180 * $multipleCount);
        background-image: url(https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018736ff-2bd7-6a84-507e-0a0b9becc3d4?x-oss-process=style/origin);
      }

      > .gc_someone {
        background-image: url(https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/0187370d-1224-b123-83d5-9ff40b4fc0aa?x-oss-process=style/origin);
      }
    }
  }

  > .minus-20 {
    height: calc(100vw - px2rem(40 * $multipleCount));
  }

  > .footer {
    @include row;
    width: calc(100% - px2rem(24 * 2 * $multipleCount));
    flex: 1;
    padding: px2rem(40 * $multipleCount) px2rem(24 * $multipleCount);
    background-color: #fff;
    overflow: hidden;

    > .left {
      flex: 1;
      height: 100%;
      overflow: hidden;

      > .title {
        @include row(flex-start, center);

        > img {
          flex-shrink: 0;
          width: px2rem(30 * $multipleCount);
          margin-right: px2rem(10 * $multipleCount);
        }

        > span {
          @include ellipsis;
          font-size: px2rem(32 * $multipleCount);
          height: px2rem(48 * $multipleCount);
          line-height: px2rem(48 * $multipleCount);
          font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
          font-weight: normal;
          color: #111111;
        }
      }
      > .item {
        @include row(flex-start, center);
        margin-top: px2rem(16 * $multipleCount);

        > img {
          &:first-of-type {
            width: px2rem(32 * $multipleCount);
            height: px2rem(32 * $multipleCount);
            border-radius: 50%;
          }

          &:nth-of-type(2) {
            width: px2rem(42 * $multipleCount) !important;
            width: px2rem(24 * $multipleCount);
            margin-left: px2rem(8 * $multipleCount);
          }
        }

        > span {
          @include ellipsis;
          font-size: px2rem(24 * $multipleCount);
          line-height: px2rem(32 * $multipleCount);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #a3a3a3;
          margin-left: px2rem(8 * $multipleCount);
        }
      }
    }

    > .right {
      @include column(flex-start, center);
      flex-shrink: 0;
      width: px2rem(142 * $multipleCount);
      height: 100%;
      margin-left: px2rem(16 * $multipleCount);

      > img {
        width: px2rem(142 * $multipleCount);
        height: px2rem(142 * $multipleCount);
        margin-top: px2rem(2 * $multipleCount);
      }

      > span {
        font-size: px2rem(24 * $multipleCount);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9f9f9f;
        line-height: px2rem(32 * $multipleCount);
        margin-top: px2rem(16 * $multipleCount);
      }
    }
  }
}

.container-minus {
  height: 137.9vw;

  > .header {
    height: calc(102vw - px2rem(44));
  }
}
</style>
