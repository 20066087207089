<!-- 潮流 -->
<template>
  <div class="container">
    <div class="header">
      <img class="bgimg" :src="img.src" />
      <div :class="['slogan', campaign.post.has_green_card ? 'slogan-green-card' : '']">
        <span class="content color-black">{{ campaign.post.author.nickname }} 带你玩!</span>
      </div>
    </div>
    <div class="footer">
      <div class="left">
        <div class="item">
          <img class="icon" :src="campaign.post.author.avatar_url" alt="">
          <span class="content">{{ campaign.campaign.title }}</span>
        </div>
        <div class="item">
          <img class="icon" src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01876f48-c850-a177-b7b6-d78ebbad71ae?x-oss-process=style/origin" alt="">
          <span class="content">{{ generateCampaignDate }}</span>
        </div>
        <div class="item">
          <img class="icon" src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01876f48-c84d-e4d2-71f6-cc278a2aea8d?x-oss-process=style/origin" alt="">
          <span class="content">{{ campaign.post.poi.name }}</span>
        </div>
      </div>
      <img class="qrcode" :src="qrcode" alt="">
    </div>
  </div>
</template>

<script>
import $fetch from "@/utils/fetch.js";
import { formatFeedsDate } from "@/utils/utils.js";
export default {
  name: "details-poster",
  props: {
    campaignId: String,
  },
  data() {
    return {
      campaign: {},
      img: {},
      qrcode: "",
      headerHeight: ""
    };
  },
  computed: {
    /** 生成活动开始时间 */
    generateCampaignDate() {
      let {
        campaign: { start_time, end_time },
      } = this.campaign;
      start_time = +new Date(start_time);
      end_time = +new Date(end_time);
      return formatFeedsDate(start_time, end_time);
    },
  },
  async created() {
    const { campaignId } = this;
    this.getCampaign(campaignId);
    this.getWechatCode(campaignId);
  },
  methods: {
    /** 获取活动详情 */
    getCampaign(campaignId) {
      Promise.all([$fetch.post("GetSharedCampaign", {
        postId: "",
        campaignId,
      }), $fetch.post("GetActivityParticipantsSummary", {
          campaignId
        })]).then(res => {
          const [campaign] = res;
          this.campaign = campaign;
          const img = campaign.post.images[0];
          console.log(campaign, img);
          if (img.video_info) {
            this.img = {
              src: img.video_info.thumbnail,
              height: img.video_info.height_px
            }
          } else if (img.image_info) {
            this.img = {
              src: img.image_info.thumbnail_url,
              height: img.image_info.height_px
            }
          }
        });
    },
    /** 获取活动的小程序码 */
    getWechatCode(campaignId) {
      const _ = this;
      $fetch
        .post("SharePost", {
          campaignId,
        })
        .then((code) => {
          _.qrcode = "data:image/jpeg;base64, " + code.mini_program_code_image;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
$multipleCount: 1.146;

.container {
  @include column;
  position: relative;
  width: 100vw;
  background-color: #000;
  overflow: hidden;

  .header {
    position: relative;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .bgimg {
      width: 100%;
    }

    .logo-box {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: px2rem(80 * $multipleCount);
      background: linear-gradient(180deg, rgba(0, 0, 0, .3), transparent);
    }

    .logo {
      position: absolute;
      right: px2rem(16 * $multipleCount);
      top: px2rem(32 * $multipleCount);
      width: px2rem(144 * $multipleCount);
    }

    .slogan {
      position: absolute;
      left: 0;
      bottom: px2rem(-70 * $multipleCount);
      width: 100%;
      height: px2rem(144 * $multipleCount);
      background: url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01876f00-87cf-8041-560b-0fced4bea368?x-oss-process=style/origin") no-repeat center center / 100%;
      z-index: 1;

      .content {
        @include ellipsis;
        position: absolute;
        top: px2rem(68 * $multipleCount);
        left: px2rem(10 * $multipleCount);
        display: block;
        font-size: px2rem(36 * $multipleCount);
        font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
        transform: rotate(-2.2deg);
        width: 80%;
      }
    }
    .slogan-green-card {
      background: url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01876f4c-13ef-3b5f-199c-eff7c699e1de?x-oss-process=style/origin") no-repeat center center / 100% !important;
    }
  }


  .footer {
    @include row;
    width: 100%;
    height: px2rem(188 * $multipleCount);
    padding-top: px2rem(70 * $multipleCount);
    background-color: #000;

    .left {
      @include column;
      flex: 1;
      margin-right: px2rem(6 * $multipleCount);
      margin-left: px2rem(12 * $multipleCount);
      overflow: hidden;

      .item {
        @include row(flex-start, center);
        width: 100%;

        .icon {
          flex-shrink: 0;
          width: px2rem(32 * $multipleCount);
          height: px2rem(32 * $multipleCount);
          border-radius: 50%;
        }

        .content {
          @include ellipsis;
          flex: 1;
          color: #fff;
          font-size: px2rem(24 * $multipleCount);
          line-height: px2rem(32 * $multipleCount);
          margin-left: px2rem(8 * $multipleCount);
          font-family: "Alibaba PuHuiTi Regular", Alibaba PuHuiTi;
        }

        &:first-child {
          margin-top: px2rem(12 * $multipleCount);
          margin-bottom: px2rem(24 * $multipleCount);

          .content {
            font-size: px2rem(28 * $multipleCount);
            font-family: "Alibaba PuHuiTi Regular", Alibaba PuHuiTi;
            font-weight: bold;
          }
        }

        &:last-child {
          margin-top: px2rem(16 * $multipleCount);
        }
      }

    }

    .qrcode {
      flex-shrink: 0;
      margin-right: px2rem(12 * $multipleCount);
      margin-top: px2rem(28 * $multipleCount);
      padding: px2rem(8 * $multipleCount);
      width: px2rem(112 * $multipleCount);
      height: px2rem(112 * $multipleCount);
      background-color: #fff;
      border-radius: 50%;
    }
  }
}
</style>
