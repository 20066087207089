<template>
  <temp1 v-if="!posterType || posterType == 1" :campaignId="campaignId" />
  <temp2 v-else-if="posterType == 2" :campaignId="campaignId" />
</template>

<script>
import temp1 from "./template/details/01.vue";
import temp2 from "./template/details/02.vue";
export default {
  name: "details-poster",
  components: { temp1, temp2 },
  data() {
    return {
      campaignId: "",
      posterType: "",
    }
  },
  created() {
    let {
      campaignId,
      posterType
    } = this.$route.query;
    this.campaignId = campaignId;
    this.posterType = posterType;
  },
};
</script>